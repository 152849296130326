import { useRouter } from 'next/router';
import { useEffect } from 'react';

import { AuthStatus } from '../redux/auth/authSlice';
import { useAppSelector } from '../redux/hooks';

export default function () {
  const router = useRouter();
  const user = useAppSelector(({ user }) => user);
  const authStatus = useAppSelector(({ auth }) => auth.status);

  useEffect(() => {
    if (authStatus === AuthStatus.LOGGED_OUT) {
      router.push('/login');
    } else {
      if (user.activeBrands.length === 1) {
        router.push(`/brand/${user.activeBrands[0].urlSlug}`);
      } else {
        router.push('/wallet');
      }
    }
  }, []);

  return null;
}
